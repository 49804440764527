<template>
  <div>
    <v-card-text>
      <v-data-table
        :items="filteredReclamacion"
        item-key="ReclamacionId"
        :headers="headerMaster"
        show-expand
        :expanded.sync="expanded"
        single-expand
        :items-per-page="5"
        :search="search"
      >
        <template v-slot:item.data-table-expand="{ expand, isExpanded, item }">
          <v-icon
            @click="
              expand(!isExpanded);
              SearchDetail(item);
            "
            >{{
              isExpanded ? "keyboard_arrow_down" : "keyboard_arrow_up"
            }}</v-icon
          >
        </template>
        <template v-slot:item.FechaAtendida="{ item }">{{
          item.FechaAtendida | date
        }}</template>
        <template v-slot:item.FechaEntrada="{ item }">{{
          item.FechaEntrada | date
        }}</template>
        <template v-slot:item.Estado="{ item }">
          <div v-if="item.ReclamacionesEstado.EstadoCode == 'PENDIENTE'">
            <v-chip color="warning" class="ma-2">
              {{ item.ReclamacionesEstado.EstadoAlternativo }}
            </v-chip>
          </div>
          <div
            v-else-if="item.ReclamacionesEstado.EstadoCode == 'SATISFACTORIO'"
          >
            <v-chip color="info" class="ma-2">
              {{ item.ReclamacionesEstado.EstadoAlternativo }}
            </v-chip>
          </div>
          <div
            v-else-if="
              item.ReclamacionesEstado.EstadoCode == 'PARCIALSATISFACTORIO'
            "
          >
            <v-chip color="secondary" class="ma-2">
              {{ item.ReclamacionesEstado.EstadoAlternativo }}
            </v-chip>
          </div>
          <div
            v-else-if="item.ReclamacionesEstado.EstadoCode == 'NOSATISFACTORIO'"
          >
            <v-chip color="primary" class="ma-2">
              {{ item.ReclamacionesEstado.EstadoAlternativo }}
            </v-chip>
          </div>
          <div v-else-if="item.ReclamacionesEstado.EstadoCode == 'FALTAINFO'">
            <v-chip color="#EB672D" class="ma-2">
              {{ item.ReclamacionesEstado.EstadoAlternativo }}
            </v-chip>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length" style="background: lightgrey">
            <br />
            <v-card
              id="detalle"
              tile
              outlined
              v-show="listDetEstado"
              class="white pa-2"
            >
              <v-tabs>
                <v-tabs-slider color="red" style="height: 5px"></v-tabs-slider>
                <v-tab href="#estados">Historial</v-tab>
                <v-tab href="#telefonos">Líneas</v-tab>
                <v-tab-item id="estados">
                  <v-card flat>
                    <v-card-text>
                      <v-data-table
                        :items="listDetEstado"
                        v-if="listDetEstado"
                        item-key="ReclamacionEstadoDetalleId"
                        :headers="headerDetailEstado"
                        hide-default-footer
                      >
                        <template v-slot:item.Fecha="{ item }">{{
                          item.Fecha | date
                        }}</template>
                        <template v-slot:item.Comentario="{ item }"
                          ><p style="white-space: normal !important">
                            {{ item.Comentario }}
                          </p></template
                        >
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item id="telefonos">
                  <v-card flat>
                    <v-card-text>
                      <v-data-table
                        :items="listDet"
                        v-if="listDet"
                        item-key="ReclamacionDetalleId"
                        :loading="loading"
                        :headers="headerDetail"
                        hide-default-footer
                      >
                        <template v-slot:item.FechaActivacion="{ item }">{{
                          item.FechaActivacion | date
                        }}</template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
            <br />
          </td>
        </template>
      </v-data-table>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer app clipped right v-model="drawerRight">
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <br />
        <v-select
          placeholder="Filtrar estado"
          clearable
          :items="listEstados"
          item-value="ReclamacionEstadoId"
          item-text="Estado"
          v-model="selectedEstado"
          append-icon="keyboard_arrow_down"
        ></v-select>
        <br />
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiReclamacion from "@/api/reclamaciones";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawerRight: true,
      search: "",
      list: [],
      expanded: [],
      listDet: null,
      listDetEstado: null,
      listEstados: [],
      selectedEstado: null,
      loading: true,
      headerMaster: [
        { text: "FECHA", value: "FechaEntrada" },
        { text: "NUMERO", value: "Numero" },
        { text: "ESTADO", value: "Estado" },
        { text: "FECHA ATENDIDA", value: "FechaAtendida" },
      ],
      headerDetail: [
        { text: "TELEFONO", value: "Telefono" },
        { text: "PRICE PLAN", value: "ProductCode" },
        { text: "FECHA ACTIVACION", value: "FechaActivacion" },
        { text: "SERIE", value: "Serie" },
        { text: "NUMERO OS", value: "NumeroOs" },
        { text: "MOTIVO", value: "Motivo" },
        { text: "CONCEPTO", value: "Concepto" },
        { text: "RESPUESTA", value: "Respuesta" },
      ],
      headerDetailEstado: [
        { text: "FECHA", value: "Fecha" },
        { text: "ESTADO", value: "ReclamacionesEstado.Estado" },
        { text: "COMENTARIO/RESPUESTA", value: "Comentario" },
        { text: "USUARIO", value: "CreatedBy" },
      ],
      paginationMaster: {
        sortBy: "FechaEntrada",
        descending: true,
      },
      paginationDetail: {
        sortBy: "Telefono",
        rowsPerPage: 25,
      },
      paginationDetailEstado: {
        sortBy: "Fecha",
        descending: true,
      },
    };
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    SearchDetail(record) {
      this.listDetEstado = [];
      this.listDet = [];
      this.loading = true;
      try {
        apiReclamacion
          .getReclamacionEstadosDetalles(record.ReclamacionId)
          .then((response) => {
            this.listDetEstado = response.data;
          })
          .catch((error) => {
            this.setError(error);
          });
        apiReclamacion
          .getReclamacionDetalles(record.ReclamacionId)
          .then((response) => {
            this.listDet = response.data;
          })
          .catch((error) => {
            this.setError(error);
          });
      } finally {
        this.loading = false;
        // document.getElementById('detalle').scrollIntoView()
      }
    },
    refresh() {
      this.listDetEstado = null;
      this.listDet = null;
      this.loading = true;
      apiReclamacion
        .getReclamacionesEmpleado()
        .then((response) => {
          this.loading = false;
          this.list = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.setError(error);
        });
    },
  },
  computed: {
    filteredReclamacion() {
      return this.list.filter((i) => {
        return (
          !this.selectedEstado ||
          i.ReclamacionesEstado.ReclamacionEstadoId === this.selectedEstado
        );
      });
    },
  },
  watch: {
    '$store.state.updateCorte': function () {
      this.refresh();
    },
  },
  mounted() {
    apiReclamacion
      .getReclamacionesEstados()
      .then((response) => {
        this.listEstados = response.data;
      })
      .catch((error) => {
        this.setError(error);
      });
    this.refresh();
  },
};
</script>
<style>
tr.expanded.expanded__content {
  box-shadow: none !important;
}
span.v-chip__content {
  text-transform: capitalize;
}
</style>